<template>
  <div v-if="curItem">
    <div class="columns">
       <div class="column">
        <p class="title is-3 has-text-danger">
          {{ curItem.tnr }} {{ curItem.title }}
        </p>
      </div>
      <div class="column is-3">
        <b-pagination
          :total="items.length"
          :current.sync="current"
          simple
          order="is-right"
          rounded
          per-page="1"
          icon-prev="arrow-left"
          icon-next="arrow-right"
        >
        </b-pagination>
      </div>
    </div>
    <div class="columns" v-if="curItem.descr">
      <div class="column" v-html="curItem.descr"></div>
    </div>
    <ManualChild
      :cat="cat"
      :items="rqmts"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import ManualChild from './ManualChild.vue'

export default {
  name: 'Manual',
  components: {
    ManualChild
  },
  data () {
    return {
      current: 1
    }
  },
  watch: {
    cat () {
      this.current = 1
    }
  },
  computed: {
    ...mapGetters({
      topics: 'manual/topics',
      reqmts: 'manual/reqmts'
    }),
    cat () {
      return this.$route.params.cat
    },
    items () {
      return this.topics.filter(t => t.catMask.includes(this.cat)).sort((a, b) => a.tnr - b.tnr)
    },
    curItem () {
      return this.items[this.current - 1]
    },
    rqmts () {
      return this.reqmts.filter(reqmt => reqmt.tnr === this.curItem.tnr && reqmt.catMask.includes(this.cat)).sort((a, b) => a.rnr - b.rnr)
    }
  }
}
</script>
