<template>
  <div>
    <b-tooltip
      v-if="prevNr"
      :label="tip"
      position="is-left"
      type="is-info"
      :triggers="['click']"
    >
      <b-icon icon="info" type="is-info" custom-class="is-clickable" />
    </b-tooltip>
    <b-icon v-if="kav" icon="edit" type="is-info" />
    <b-icon v-if="iso" icon="edit" type="is-success" />
    <b-icon v-if="def" icon="exclamation-triangle" type="is-success" :custom-class="defCls" />
  </div>
</template>

<script>
export default {
  props: {
    hints: {
      type: Array,
      default: () => []
    },
    grade: {
      type: Number,
      default: 0
    },
    prevNr: {
      type: String
    }
  },
  computed: {
    kav () {
      return this.hints.includes('k')
    },
    iso () {
      return this.hints.includes('i')
    },
    def () {
      return this.hints.includes('d')
    },
    defCls () {
      return this.grade === 1 ? 'has-text-danger' : this.grade === 2 ? 'has-text-warning' : 'has-text-grey-light'
    },
    tip () {
      return `Vorherige Nummerierung: ${this.prevNr}`
    }
  }
}
</script>
