<template>
  <div>
    <div v-for="rqm in items" :key="rqm.ri">
      <div class="columns">
        <div class="column">
          <p class="title is-5" :style="{ color: rqm.color }">
            {{ rqm.tnr }}.{{ rqm.rnr }} {{ rqm.title }}
          </p>
        </div>
      </div>
      <div class="columns" v-for="(crt, number) in getCriterions(rqm.ri)" :key="number">
        <div class="column is-3">
          <p class="title is-6" :style="{ color: rqm.color }">
            {{ crt.title }}
          </p>
        </div>
        <div class="column">
          <div>{{ crt.descr }}</div>
        </div>
        <AuditHint class="column is-1" :hints="crt.hintMask" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import AuditHint from './AuditHint.vue'

export default {
  name: 'ManualChild',
  components: {
    AuditHint
  },
  props: {
    cat: String,
    items: {
      type: Array
    }
  },
  computed: {
    ...mapGetters({
      criteria: 'manual/criteria'
    })
  },
  methods: {
    getCriterions (ri) {
      return this.criteria.filter(
        criterion =>
          criterion.ri === ri && criterion.catMask.includes(this.cat)
      )
    }
  }
}
</script>
